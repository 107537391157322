import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { Container } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';
import Burger from '../burger';

// import Logo from '../../images/images-js/bb-logo'

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
 
    transition: {
      ease: 'easeInOut',
    },
    opacity: 1
  },
  exit: {
    transition: {
      ease: 'easeInOut',

    },
   
    opacity: .5
  },
});

const Header = (props) =>{
  console.log(props.burgerClass)
  return(
    <div className="header-wrapper container-fluid header-wrapper">
  <div className="container top" >
    <Burger whiteClass={props.burgerClass}/>
      <div className="top-logo">
     <Link to="/"><img src="/pictures/white-logo.svg" alt="logo"/></Link> 
      </div>
   <AnimatedContainer>
      <Container>
        <Link to="/">
          <img src="/pictures/black-new-logo.jpg" alt=""/>
        </Link>
        <Nav />
      </Container>
    </AnimatedContainer>
    </div>
    </div>
   
   
  );
} 

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;







