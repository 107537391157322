import React, { Component } from 'react';
import { Link } from 'gatsby';


export default class Nav extends Component {
  state = {
    isOpen: false,
  };
  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  render() {
    return (
      <nav className="navigation">
        <ul>
          <li>
            <Link activeClassName="active-link" to="/">Home</Link>
          </li>
          <li>
            <Link activeClassName="active-link"  to="/o-mnie">O MNIE</Link>
          </li>
          <li>
            <Link activeClassName="active-link"  to="/wspolpracownicy">WSPÓŁPRACOWNICY</Link>
          </li>
          <li>
            <Link activeClassName="active-link"  to="/uslugi">USŁUGI</Link>
          </li>
          <li>
            <Link activeClassName="active-link"  to="/szkolenia">SZKOLENIA</Link>
          </li>
          <li>
            <Link activeClassName="active-link"  to="/blog">BLOG</Link>
          </li>
          <li className="contact-link">
            <Link  activeClassName="active-link"  to="/kontakt">KONTAKT</Link>
          </li>
        </ul>
      </nav>
    );
  }
}
