import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer__logo">
          <img src="/pictures/black-new-logo.jpg" alt="logo"/>
          </div>
          <div className="footer__info">
            <div className="footer__contact">
              <div className="footer__address">
                <img src="/pictures/place.svg" alt="ikona lokalizacji" />
                <span>
                  ul. Widok 8 lok. 5 <br />
                  00-023 Warszawa
                </span>
              </div>
              <div className="footer__email">
                <img src="/pictures/message.svg" alt="ikona email" />
                <span>biuro@bartoszbator.pl</span>
              </div>
              <div className="footer__telephone">
                <img src="/pictures/phone-call.svg" alt="ikona telefonu" />
                <a href="tel:+48 603 082 948">+ 48 603 082 948</a>
              </div>
              <div className="footer__media">
                <img src="/pictures/facebook.svg" alt="ikona facebook" />
                <img className="linkd" src="/pictures/linkedin-logo.svg" alt="ikona lindkein" />
              </div>
            </div>
            <div className="footer__links">
              <Link to="/">HOME</Link>
              <Link to="/o-mnie">O MNIE</Link>
              <Link to="/wspolpracownicy">WSPÓŁPRACOWNICY</Link>
              <Link to="/uslugi">USŁUGI</Link>
              <Link to="/szkolenia">SZKOLENIA</Link>
              <Link to="/kontakt">KONTAKT</Link>
            </div>
          </div>
        </div>
        <span className="copy">2021. ALL RIGHT RESERVED</span>
      </div>
    </footer>
  );
};

export default Footer;
