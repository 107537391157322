import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import GlobalStyle from 'global.css.js';
import '../../styles/main.scss';
import { Helmet } from "react-helmet";
import Footer from 'components/footer';
import CookieConsent from 'react-cookie-consent';
const Layout = ({ data, children ,buttonClass}) => (

  <div className="">
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Marck+Script&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=GFS+Didot&family=Raleway:wght@100;200;300;400&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap" rel="stylesheet"></link>
    </Helmet>
    <GlobalStyle />
    <Head />
    <Header burgerClass={buttonClass} title={data.site.siteMetadata.siteTitle} />
    {children}
    <Footer/>
    <CookieConsent
          location="bottom"
          buttonText="Zamknij"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.
</CookieConsent>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
